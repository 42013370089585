<template>
  <div class="brent809_tm_section" id="about">
    <div class="section_inner">
      <div class="brent809_tm_about">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/1-1.jpg" alt="" />
            <div class="main" data-img-url="img/about/1.png"></div>
          </div>
        </div>
        <div class="right">
          <div class="short">
            <h3 class="name">Brent <span class="coloring">809</span></h3>
            <h3 class="job">
              <AnimationText />
            </h3>
          </div>
          <div class="text">
            <p>
              Hey, I'm
              <span>Brent Schoenmakers!</span> I've been into website
              development for almost 5 years, constantly improving. I've also
              explored full stack web development for 3 years. Excited
              about the prospect of teaming up with you!
            </p>
          </div>
          <div class="brent809_tm_button" id="aboutPopup">
            <a href="#">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimationText from "./AnimationText.vue";
export default {
  name: `AboutComponent`,
  data() {
    return {
      open: false,
    };
  },
  components: {
    AnimationText,
  },
  metaInfo: {
    title: 'About',
  }
};
</script>

<style scoped>
.brent809_tm_modalbox {
  top: -90px;
}
</style>
