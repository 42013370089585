<template>
  <div id="preloader">
    <div class="loader_line"></div>
  </div>
</template>

<script>
import { brent809PreLoader } from "@/utilits";

export default {
  name: `PreloaderComponent`,
  mounted() {
    brent809PreLoader();
  },
};
</script>
