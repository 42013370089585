import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Analytics
import SimpleAnalytics from "simple-analytics-vue";

const app = createApp(App);
app.use(SimpleAnalytics, { skip: process.env.NODE_ENV !== "production" });

app.use(router).mount("#app");
