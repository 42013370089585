<template>
  <ul class="transition_link" style="display: inline-flex; align-items: center;">
    <li
      :class="navFuntions.activeNavCls('home')"
      style="vertical-align: middle"
    >
      <a href="#home" @click="navFuntions.activeSection('home')">Home</a>
    </li>
    <li
      :class="navFuntions.activeNavCls('about')"
      style="vertical-align: middle"
    >
      <a href="#about" @click="navFuntions.activeSection('about')">About</a>
    </li>
    <li
      :class="navFuntions.activeNavCls('portfolio')"
      style="vertical-align: middle"
    >
      <a href="#portfolio" @click="navFuntions.activeSection('portfolio')"
        >Portfolio</a
      >
    </li>
    <!-- <li :class="navFuntions.activeNavCls('gigs')">
      <a href="#gigs" @click="navFuntions.activeSection('gigs')">Fiverr gigs</a>
    </li> -->
    <li
      :class="navFuntions.activeNavCls('contact')"
      style="vertical-align: middle"
    >
      <a href="#contact" @click="navFuntions.activeSection('contact')"
        >Contact</a
      >
    </li>
    <li>
      <div
        class="brent809_tm_button transition_link"
        style="vertical-align: middle"
      >
        <a href="cv.pdf">CV</a>
      </div>
    </li>
  </ul>
</template>

<script>
import { navFuntions } from "@/navFuntions";
export default {
  name: `Menu`,
  data() {
    return {
      navFuntions,
    };
  },
};
</script>
