<template>
  <div class="brent809_tm_section" id="gigs">
    <div class="section_inner">
      <div class="brent809_tm_news swiper-section">
        <div class="brent809_tm_main_title">
          <h3>Fiverr <span class="coloring">Gigs</span></h3>
        </div>
        <div class="news_list">
          <swiper
            :loop="false"
            :slidesPerView="1"
            :spaceBetween="0"
            :loopAdditionalSlides="1"
            :autoplay="{
              delay: 6000,
            }"
            :navigation="{
              nextEl: '.my_next',
              prevEl: '.my_prev',
            }"
            :pagination="pagination"
            :breakpoints="{
              700: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }"
            @slideChange="onSlideChange"
            :modules="modules"
            class="swiper-container"
          >
            <swiper-slide
              class="swiper-slide"
              v-for="(data, i) in newsData"
              :key="i"
            >
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-3.jpg" alt="" />
                  <div class="main" :data-img-url="data.img"></div>
                  <a class="brent809_tm_full_link news_popup" href="#"></a>
                </div>
                <div class="details">
                  <h3>
                    <a href="#">{{ data.title }}</a>
                  </h3>
                  <span
                    ><a href="#">{{ data.tag }}</a></span
                  >
                  <div class="brent809_tm_button transition_link">
                    <a :href="data.link">Check out</a>
                  </div>
                </div>

                <!-- News Popup Informations -->
                <div class="brent809_tm_hidden_content">
                  <div class="news_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div class="main" :data-img-url="data.img"></div>
                    </div>
                    <div class="news_main_title">
                      <h3>{{ data.title }}</h3>
                      <span
                        ><a href="#">{{ data.tag }}</a></span
                      >
                      <div></div>
                    </div>
                    <div class="text">
                      <p v-for="(text, t) in data.des" :key="t">
                        {{ text }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- /News Popup Informations -->
              </div>
            </swiper-slide>
            <div class="brent809_tm_swiper_progress fill">
              <div class="my_pagination_in">
                <span class="currentNews">0</span>
                <span class="pagination_progress"
                  ><span class="all allNews"><span></span></span
                ></span>
                <span class="totalNews">04</span>
              </div>
              <div class="my_navigation">
                <ul>
                  <li>
                    <a class="my_prev" href="#"
                      ><i class="icon-left-open-1"></i
                    ></a>
                  </li>
                  <li>
                    <a class="my_next" href="#"
                      ><i class="icon-right-open-1"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperSliderCustomSlider } from "../utilits";

export default {
  name: `NewsComponent`,
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSlideChange(swiper) {
      this.activeSlider = swiper.activeIndex + 1;
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".brent809_tm_swiper_progress",
        type: "custom", // progressbar
        renderCustom: function (swiper, current, total) {
          swiperSliderCustomSlider(
            current,
            total,
            "currentNews",
            "totalNews",
            "allNews"
          );
        },
      },
    };
  },
  data() {
    return {
      newsData: [
        {
          title: "I will develop high end android app using ionic",
          img: "img/gigs/mobileapp.png",
          tag: "Android app development",
          des: [
            "Why not hire talented individua with over 5 years of hand on experience as a professional application developer?",
            "If you want to create a high-performing, cross-platform mobile app that will set your company apart, you've come to the right spot. I am a professional Ionic developer who can assist you in bringing your project to life.",
            "Ionic is a strong framework that enables faster creation and deployment of mobile apps while also allowing code to be reused across iOS and Android platforms. This implies that your software will not only run smoothly across all devices, but you will also save time and money during the development process.",
            "With this gig, I can",
            "     - Develop a mobile application in the ionic framework using AngularJS.",
            "     - 100% quality code written for you adapted to your application.",
            "     - Fix the issues of ionic application.",
            "     - Fix HTML and CSS issues.",
            "     - provide the solutions and functionality of AngularJS.",
            "     - Fix plugin errors.",
            "     - Fix multiple device compatibility issues.",
            "",
            "Don't settle for a below average mobile app. Allow me to assist you in taking your company to the next level with a cutting-edge, high-performance Ionic app. Get in touch with me today to get started.",
            "In today’s digital world, your website is the first interaction consumers have with your business. That's why almost 95 percent of a user’s first impression relates to web design. It’s also why web design services can have an immense impact on your company’s bottom line.",
            "That’s why more companies are not only reevaluating their website’s design but also partnering with Kura, the web design agency that’s driven more than $2.4 billion in revenue for its clients. With over 50 web design awards under our belt, we're confident we can design a custom website that drives sales for your unique business.",
          ],
          link: "https://www.fiverr.com/brent809brent809/make-a-functional-android-app-in-ionic",
        },
        {
          title: "I will create modern HTML, CSS, js website",
          img: "img/gigs/webdevelopment.png",
          tag: "Website development",
          des: [
            "Are you in need of a sleek and professional one page webpage? Look no further! I am a web developer with expertise in HTML, CSS, and JavaScript, and I am here to help you create the perfect static one page website to showcase your business or personal brand.",
            "With this gig, you will receive a custom-designed, responsive website optimized for all devices. I will work closely with you to understand your vision and bring it to life with a clean, modern design and smooth user experience.",
            "My one page website will include:",
            "     - Custom design to match your brand",
            "     - Responsive design for optimal viewing on all devices",
            "     - HTML, CSS, and JavaScript for a fast-loading, interactive website",
            "     - Cross-browser compatibility",
            "Don't settle for a generic template website. Let me help you stand out with a unique and professional one page webpage.",
            "Order now and let's get started!",
            "",
          ],
          link: "https://www.fiverr.com/brent809brent809/make-a-custom-modern-animated-static-website-in-html",
        },
        {
          title: "I will develop a responsive website or web app in angular",
          img: "img/gigs/ng.png",
          tag: "Angular development",
          des: [
            "Welcome to my gig! I am a professional web developer with expertise in AngularJS, NodeJS, and Express backend. I can help you create a responsive website or web app in Angular that will provide a seamless user experience across different devices.",
            "My services include:",
            "Bug Fixes - I can help you fix any bugs or issues in your existing Angular application.",
            "Basic Frontend Web App - I can develop a basic frontend web app in AngularJS and NodeJS. This package includes up to 5 pages of your choice and integration with your preferred API.",
            "Full Stack Web App - I can develop a complete web app in AngularJS and Express backend. This package includes up to 10 pages of your choice, integration with your preferred API, and a responsive design.",
            "Why choose me?",
            "I have years of experience in web development, and I'm dedicated to providing high-quality services that meet your needs. I'll keep you updated throughout the development process, and I'm always available to answer any questions you may have.",
            "Please feel free to contact me if you have any questions or to discuss your project requirements. I look forward to working with you!",
            "",
          ],
          link: "https://www.fiverr.com/brent809brent809/make-a-custom-modern-animated-static-website-in-html",
        },
      ],
    };
  },
};
</script>
