<template>
  <div class="brent809_tm_section" id="contact">
    <div class="section_inner">
      <div class="brent809_tm_contact">
        <div class="brent809_tm_main_title">
          <h3>Get in <span class="coloring">Touch</span></h3>
        </div>
        <div class="wrapper">
          <div class="left">
            <ul>
              <li>
                <div class="list_inner">
                  <i class="icon-phone"></i>
                  <span><a href="#">+32 474 06 09 87</a></span>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <i class="icon-mail-1"></i>
                  <span><a href="mailto:brent@brent809.com">brent@brent809.com</a></span>
                </div>
              </li>
            </ul>
          </div>
          <div class="right">
            <div class="fields">
              <form action="https://formsubmit.co/brent@brent809.com" class="contact_form" id="contact_form" method="post">
                <div class="returnmessage" data-success="Your message has been received, We will contact you soon.">
                </div>
                <div class="empty_notice">
                  <span>Please Fill Required Fields</span>
                </div>
                <div class="first">
                  <ul>
                    <li>
                      <div class="list_inner">
                        <input id="name" type="text" required placeholder="Name" name="name" autocomplete="off" />
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <input id="email" type="email" required placeholder="Email" name="email" autocomplete="off" />
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="last">
                  <textarea id="message" placeholder="Message" name="message"></textarea>
                </div>
                <div class="brent809_tm_button" id="send_message" style="cursor: pointer;">
                  <a style="cursor: pointer;" onclick="this.closest('form').submit();return false;">Send message</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `ContactComponent`,
};
</script>
